import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
import {convertLocalToUTC} from "@/helpers/dateHelper";

export class ReportService{
    constructor() {
    }
    async getEntitiesInvoicesSummary(filters){
        filters.startDate = convertLocalToUTC(filters.startDate);
        filters.endDate = convertLocalToUTC(filters.endDate);
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.report.entitiesInvoicesSummary, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, rows: response.data.rows, total: response.data.total};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', rows: [], total: 0};
            });
    }
    async getEntityLedger(filters){
        filters.startDate = convertLocalToUTC(filters.startDate);
        filters.endDate = convertLocalToUTC(filters.endDate);
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.report.entityLedger, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, rangeRows: response.data.rangeRows, rangeTotal: response.data.rangeTotal, prevTotal: response.data.prevTotal};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', rangeRows: [], rangeTotal: 0, prevTotal: 0};
            });
    }
    async getInflowOutflow(filters){
        filters.startDate = convertLocalToUTC(filters.startDate);
        filters.endDate = convertLocalToUTC(filters.endDate);
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.report.inflowOutflow, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, customers_total: response.data.customers_total, inflow_accounts_total: response.data.inflow_accounts_total, inflow_rows: response.data.inflow_rows, suppliers_total: response.data.suppliers_total, outflow_accounts_total: response.data.outflow_accounts_total, outflow_rows: response.data.outflow_rows};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', customers_total: 0, inflow_accounts_total: 0, inflow_rows: [], suppliers_total: 0, outflow_accounts_total: 0, outflow_rows: []};
            });
    }
    async getInvoicesList(filters){
        filters.startDate = convertLocalToUTC(filters.startDate);
        filters.endDate = convertLocalToUTC(filters.endDate);
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.report.invoicesList, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, rangeRows: response.data.rangeRows, rangeTotal: response.data.rangeTotal, prevTotal: response.data.prevTotal};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', rangeRows: [], rangeTotal: 0, prevTotal: 0};
            });
    }
    async getProductLedger(filters){
        filters.startDate = convertLocalToUTC(filters.startDate);
        filters.endDate = convertLocalToUTC(filters.endDate);
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.report.productLedger, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, rangeRows: response.data.rangeRows, rangeTotal: response.data.rangeTotal, prevTotal: response.data.prevTotal};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', rangeRows: [], rangeTotal: 0, prevTotal: 0};
            });
    }
    async getProductsInventory(filters){
        filters.startDate = convertLocalToUTC(filters.startDate);
        filters.endDate = convertLocalToUTC(filters.endDate);
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.report.productsInventory, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, rows: response.data.rows};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', rows: []};
            });
    }
}